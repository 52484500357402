import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
  }
  html, body{
    max-width:100vw;
    overflow-x:hidden;
    
    -webkit-font-smoothing: antialiased;
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.font.sans};
    font-size: ${({ theme }) => theme.fontSize.base};
    color: ${({ theme }) => theme.color.white};
  }
  body {
    line-height: ${({ theme }) => theme.lineHeight.relaxed};
    letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
    background-color: ${({ theme }) => theme.color.black};
    font-size:3.125rem;
    line-height:3.88125rem;
    font-weight:300;
    @media (max-width: 768px) {
    }
  }
  a{
    &, &:visited , &:active, &:focus {
      color: ${({ theme }) => theme.color.white};
      text-decoration:none;
    }
    &:hover{
      color: ${({ theme }) => theme.color.grey['400']};
    }
  }
  h1, h2, h3, h4, h5, h6{
    max-width: Calc(100vw - ${({ theme }) => theme.margin.left});
    margin: 0 auto;
    @media screen and (max-width:640px){
      max-width:100%;
    }
    i{
      color:${({ theme }) => theme.color.lavender};
    }
  }
  h1 {
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    font-size: 2.89625rem;
    line-height: 4.15625rem;
    margin-bottom: 4.8125rem;
    color: white;
    @media all and (max-width: ${props => props.theme.breakpoints.desktop_sm}) {
      font-size: 3.125rem;
      
      line-height: 3.75rem;
    }
  }

  h2{
    font-size: 1.6875rem;
    line-height: 2.125rem;
    letter-spacing:0.03375rem;
    font-weight:700;
    margin-bottom:0.75rem;
  }
  p{
    margin-bottom:10px;
    font-size: 1.5625rem;
    line-height:2.125rem; 
    letter-spacing:0;
    font-weight:300;
    @media all and (max-width: ${props => props.theme.breakpoints.desktop_sm}) {
    }
  }
  label{
    font-size: 0.9375rem;
    line-height:1.125rem;
    letter-spacing:0.0325rem;
  }
  sup{
    font-size:0.4em;
    vertical-align:text-top;
    line-height:1em;
  }
  select {
    color: #9e9e9e;
    &.selected{
      color:black;
    }
  }
  option:not(:first-of-type) {
    color: black;
  }
  ::placeholder,:-ms-input-placeholder, ::-ms-input-placeholder {
    color: ${({ theme }) => theme.color.grey.default};
  }
  .noWrap{
    white-space:nowrap;
  }
  button{
    font-family: ${({ theme }) => theme.font.sans};
  }
  @media screen and (min-width:641px){
    .smallBreakpoint{
        display:none;
    }
  }
  button{
    border:1px solid ${({ theme }) => theme.color.waxOrange};
    background:none;
    color:${({ theme }) => theme.color.waxOrange};
    padding: 0 1.5rem;
    height: 2.625rem;
    border-radius: 0.5625rem;
    font-weight:600;
    font-size: 0.75rem;
  }
  .uppercase{
    text-transform:uppercase;
  }
  .lightWeight{
    font-weight:300;
  }
  .airWeight{
    font-weight:100;
  }
  em{
    font-style:italic;
  }
  button{
    width:166px;
    height:166px;
    background:white;
    border:none;
    color:black;
    border-radius:50%;
    font-family: ${({ theme }) => theme.font.sans};
    font-size: 1.25rem;
    text-transform:uppercase;
    cursor:pointer;
    transition:all 0.3s ease-out;
    user-select:none;
    &:disabled, &.disabled{
      opacity:0.75;
      background:#222;
      color:#666;
      border-color:#666;
    }
    &:hover{
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:active{
      background:#CCCCCC;
      border:1px solid black;
      color:black;
    }
  }
  input{
    color:white;
    &[type=email],&[type=text]{
     
      height:100px;
      
      text-align:center;
      background:none;
      border:1px solid white;
      font-weight:100;
    }
    &[type=email]{
      width:54vw;
    }
    &[type=text]{
      width:50%;
    }
  }
  input.large{
    font-size:3rem;
  }
  input::placeholder{
    font-size:0.75rem;
    letter-spacing: 0.075rem;
    text-transform:uppercase;
  }
  .sans{
    font-family: ${({ theme }) => theme.font.sans};
    font-weight:bold;
  }
  footer{
    margin-top:100px;
  }
`;
