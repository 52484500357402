import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { theme } from '@/theme/Theme';
import { GlobalStyle } from '@/theme/GlobalStyle';

import { resetSelection } from '@/data/AssetSelection';
import { Profile } from '@/data/Profile';

import Header from '@atoms/Header';
import Footer from '@atoms/Footer';

import { PageContext } from '@/context';

import { getSelectedNFTName } from '@/utils';

// import bg from '@/images/pixelPerfect/intro.jpg';
// import bg from '@/images/pixelPerfect/onboarding.jpg';
// import bg from '@/images/pixelPerfect/onboarding_02.jpg';
// import bg from '@/images/pixelPerfect/options_01.jpg';
// import bg from '@/images/pixelPerfect/options_02.jpg';
// import bg from '@/images/pixelPerfect/minting.jpg';
// import bg from '@/images/pixelPerfect/confirm.jpg';
// import bg from '@/images/pixelPerfect/video_0.jpg';
// import bg from '@/images/pixelPerfect/video_1.jpg';
import bg from '@/images/pixelPerfect/video_3.jpg';
// import bg from '@/images/pixelPerfect/finalReview.jpg';
// import bg from '@/images/pixelPerfect/desktopShare.jpg';
// import bg from '@/images/pixelPerfect/share.jpg';
// import bg from '@/images/pixelPerfect/finalReview.jpg';

import { PageWrapper } from '@components/StyledComponents';

let AssetSelection = resetSelection();

const Page = ({ broadcastMSG, children, stateSlide, setStateSlide, footer }) => {
  const [stateProfile, setStateProfile] = useState(Profile);
  useEffect(() => {
    if (stateSlide === 0) {
      setStateProfile(Profile);
      AssetSelection = resetSelection();
    }
  }, [stateSlide]);

  return (
    <PageContext.Provider
      value={{
        broadcastMSG: broadcastMSG,
        clickNext: () => {
          setStateSlide(stateSlide => stateSlide + 1);
        },
        clickPrev: () => {
          setStateSlide(stateSlide - 1);
        },
        setParentSlide: value => {
          setStateSlide(value);
        },
        stateSlide,
        stateProfile,
        setStateProfile,
        AssetSelection: { ...AssetSelection },
      }}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <PageWrapper bg={bg} className="pageWrapper">
          {children}
        </PageWrapper>
      </ThemeProvider>
    </PageContext.Provider>
  );
};

export default Page;
