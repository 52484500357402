import bg1 from '@/videos/ss23/09_TA_BG_IMAGE_1.mp4';
import bg2 from '@/videos/ss23/09_TA_BG_IMAGE_9.mp4';
import bg3 from '@/videos/ss23/08_TS _BG _FOOTAGE_4.mp4';
import bg4 from '@/videos/ss23/08_TS _BG _FOOTAGE_6.mp4';
import bg5 from '@/videos/ss23/08_TS _BG _FOOTAGE_9.mp4';
import bg6 from '@/videos/ss23/08_TS _BG _FOOTAGE_10.mp4';
import bg7 from '@/videos/ss23/08_TS _BG _FOOTAGE_11.mp4';

const defaultSelection = {
  options: {
    base: {
      selected: 0,
      values: [bg1, bg2, bg3, bg4, bg5, bg6, bg7],
    },
  },
};

// export const AssetSelection = {
//   ...defaultSelection,
// };

export const resetSelection = () => {
  return JSON.parse(JSON.stringify(defaultSelection));
};
